$('.stars-specialization-7days__slider').slick({
    arrows:true,
    slidesToShow:1,
    centerPadding: '40px',
    centerMode: false,
    dots: false,
    autoplay:true,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '50px',
                dots: true,
                autoplay:true,
                arrows:false,
                centerMode: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '50px',
                dots: true,
                autoplay: true,
                arrows: false,
                centerMode: true,
            }
        },

    ]
});
$('.lk-7days__editor-form_rubric').selectric()
