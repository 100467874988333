$('.video-7days__base-rating-select_options').selectric();
$('.video-7days__base-selection-series').slick({
    arrows: false,
    slidesToShow: 3,
    centerMode: false,
    dots: false,
    autoplay: false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '20px',
                dots: true,
                autoplay: true,
                arrows: false,
                centerMode: true,
            }
        },


    ]
});
