import Swiper from "swiper";

let swiper_list = $("[data-slider-id]");
$(swiper_list).each(function () {
    var swiper_slider_id = $(this).attr('data-slider-id');
    var mySwiperSale = new Swiper('.shopping-7days__swiper', {
        slidesPerView: 1.5,
        spaceBetween: 20,
        navigation: {
            nextEl: '.shopping-7days__swiper-next',
            prevEl: '.shopping-7days__swiper-prev'
        },
        scrollbar: {
            el: '.shopping-7days__scrollbar',
            draggable: true
        },
        breakpoints: {
            984: {
                slidesPerView: 3,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.shopping-7days__swiper-next',
                    prevEl: '.shopping-7days__swiper-prev'
                },
            },
        }
    })
});

$(swiper_list).each(function () {
    var swiper_slider_id = $(this).attr('data-slider-id');
    var mySwiperSale = new Swiper('.shopping-7days__swiper.shopping-7days__swiper_full', {
        slidesPerView: 1.5,
        spaceBetween: 20,
        navigation: {
            nextEl: '.shopping-7days__swiper-next',
            prevEl: '.shopping-7days__swiper-prev'
        },
        scrollbar: {
            el: '.shopping-7days__scrollbar',
            draggable: true
        },
        breakpoints: {
            984: {
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.shopping-7days__swiper-next',
                    prevEl: '.shopping-7days__swiper-prev'
                },
            },
        }
    })
});


$('.shopping-7days__index-slider').slick({
    arrows: false,
    slidesToShow: 3,

    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                centerPadding: '40px',
                dots: true,
                autoplay: true,
                arrows: false,
                centerMode: true,
            }
        },

    ]
});







