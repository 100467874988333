function changeTab(tabNumber) {

    var tabContents = document.querySelectorAll('.tab-content');
    var tabButtons = document.querySelectorAll('.tab-button');


    tabContents.forEach(tab => tab.classList.remove('active-tab'));
    tabButtons.forEach(button => button.classList.remove('active'));


    var selectedTab = document.querySelector('.tabsTaroTab-' + tabNumber);
    if (selectedTab) {
        selectedTab.classList.add('active-tab');
    }

    var selectedButton = document.querySelectorAll('.tab-button[data-tab="' + tabNumber + '"]');
    if (selectedButton) {
        selectedButton.forEach(button => button.classList.add('active'));
    }
}


document.querySelectorAll('.tabsTaro, .taro-7days__nav').forEach(tabContainer => {
    tabContainer.addEventListener('click', function(event) {
        if (event.target.classList.contains('tab-button')) {
            var tabNumber = event.target.getAttribute('data-tab');
            changeTab(tabNumber);
        }
    });
});
